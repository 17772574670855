import React from 'react'
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import SEO from "../components/seo"
import './artist.css'

export default function Artists(props) {
    
    const allArtist = props.data.allContentfulArtist.edges
    
    allArtist.sort((a, b) => {
        return a.node.order - b.node.order;
    });

    const renderArtist = () => {
       return allArtist.map(artist => 
                <div key={artist.node.order}className="artistName">
                        <Link to={`/artists/${artist.node.slug}/works`} className="artistText">
                            {artist.node.name}
                        </Link>
                    </div>)  
    }

    return (
        <Layout>
          <SEO description="List of all artist represented by the gallery bureau"  title="Artists" />
          {/* <h1 style={{visibility: "collapse"}}>Artists</h1> */}
            <div className='list-container'>
                <ul className='list'> 
                {renderArtist()}
                </ul>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
query artist {
    allContentfulArtist{
      edges {
        node {
          slug
          name
          order
        }
      }
    }
  }
`
